import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)


const router = new Router({
  base: process.env.VUE_APP_PUBLICPATH,
  mode: 'history',
  routes: [
    {
      path: '/',
      meta: {
        title: 'Página Principal',
        requiresAuth: false
      },
      // route level code-splitting this generates a separate chunk (xxxx.[hash].js) for this route which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "PaginaPrincipal" */ './views/public/PaginaPrincipal.vue')
    },
    {
      path: "*",
      meta: { IsNotFound: true }
    },
    {
      path: "/Health",
      meta: { IsHealthCheck: true }
    }
  ]
});


router.beforeEach (VueUtils.RouterBeforeEach);


export default router;