import Vue from 'vue'
import App from './App.vue'
import router from './router'


Vue.mixin({
  
  //data() {
    // return { User: User }
  //},

  mounted() {

    this.ApplicationName = "ESF.Portal.FrontEnd";

    this.ValidateNested = pOKFunction => VueUtils.ValidateNested (this, pOKFunction);
  }
});

new Vue({
  router,
  store: null,
  render: h => h(App)
}).$mount('#app');